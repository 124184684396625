import { Component, OnInit, ViewChild, TemplateRef, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CalendarOptions, EventClickArg, EventApi, EventInput } from '@fullcalendar/angular';
import { calendarEvents, category, createEventId } from 'app/pages/calendar/data';

import Swal from 'sweetalert2';
import { DataTasks } from 'app/view/tasks-without-project/modal/tasks';
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit , OnChanges {

  // bread crumb items
  breadCrumbItems: Array<{}>;

  @ViewChild('modalShow') modalShow: TemplateRef<any>;
  @ViewChild('editmodalShow') editmodalShow: TemplateRef<any>;

  @Output() onView:EventEmitter<number> = new EventEmitter<number>();
  @Output() onEdit:EventEmitter<number> = new EventEmitter<number>();


  @Input() data:EventInput[] = [];
  formEditData: FormGroup;
  submitted = false;
  category: any[];
  newEventDate: any;
  editEvent: any;
  calendarEvents: any[];
  // event form
  formData: FormGroup;

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek',
      center: 'title',
      right: 'prevYear,prev,next,nextYear'
    },
    initialView: "dayGridMonth",
    themeSystem: "bootstrap",
    // initialEvents: this.data,
    initialEvents: this.data,
    // dayCount: 20,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    // dateClick: this.viewModel.bind(this),
    eventClick: this.viewModel.bind(this),
    // eventsSet: this.viewModel.bind(this),
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
      hour12: false,
    }
  };
  currentEvents: EventApi[] = [];

  // editModel(clickInfo: EventClickArg){
  //   console.log(clickInfo.event.id);
  // }
  viewModel(clickInfo: EventClickArg){
    const id = +clickInfo.event.id;
    this.onView.emit(id);
  }
  ngOnInit(): void {
    // this.breadCrumbItems = [{ label: 'Skote' }, { label: 'Calendar', active: true }];
  }

  /**
   * Event click modal show
   */
  // handleEventClick(clickInfo: EventClickArg) {
  //   this.editEvent = clickInfo.event;
  //   this.formEditData = this.formBuilder.group({
  //     editTitle: clickInfo.event.title,
  //     editCategory: clickInfo.event.classNames[0],
  //   });
  //   this.modalService.open(this.editmodalShow);
  // }

  /**
   * Events bind in calander
   * @param events events
   */
  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) {}


  /**
   * Delete-confirm
   */
  // confirm() {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You won\'t be able to revert this!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       this.deleteEventData();
  //       Swal.fire('Deleted!', 'Event has been deleted.', 'success');
  //     }
  //   });
  // }

  // position() {
  //   Swal.fire({
  //     position: 'center',
  //     icon: 'success',
  //     title: 'Event has been saved',
  //     showConfirmButton: false,
  //     timer: 1000,
  //   });
  // }

  /**
   * Event add modal
   */
  // openModal(event?: any) {
  //   this.newEventDate = event;
  //   this.modalService.open(this.modalShow);
  // }

  /**
   * save edit event data
   */
  // editEventSave() {
  //   const editTitle = this.formEditData.get('editTitle').value;
  //   const editCategory = this.formEditData.get('editCategory').value;
    
  //   const editId = this.calendarEvents.findIndex(
  //     (x) => x.id + '' === this.editEvent.id + ''
  //   );

  //   this.editEvent.setProp('title', editTitle);
  //   this.editEvent.setProp('classNames', editCategory);

  //   this.calendarEvents[editId] = {
  //     ...this.editEvent,
  //     title: editTitle,
  //     id: this.editEvent.id,
  //     classNames: editCategory + ' ' + 'text-white',
  //   };

  //   this.position();
  //   this.formEditData = this.formBuilder.group({
  //     editTitle: '',
  //     editCategory: '',
  //   });
  //   this.modalService.dismissAll();
  // }

  /**
   * Delete event
   */
  deleteEventData() {
    this.editEvent.remove();
    this.modalService.dismissAll();
  }

  /**
   * Close event modal
   */
  closeEventModal() {
    this.modalService.dismissAll();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes) {
        console.log(this.data);
        
        this.calendarOptions.initialEvents = this.data;
      }
  }
  /**
   * Save the event
   */
  // saveEvent() {
  //   if (this.formData.valid) {
  //     const title = this.formData.get('title').value;
  //     const className = this.formData.get('category').value;
  //     const calendarApi = this.newEventDate.view.calendar;
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: this.newEventDate.date,
  //       end: this.newEventDate.date,
  //       className: className + ' ' + 'text-white'
  //     });
  //     this.position();
  //     this.formData = this.formBuilder.group({
  //       title: '',
  //       category: '',
  //     });
  //     this.modalService.dismissAll();
  //   }
  //   this.submitted = true;
  // }

  /**
   * Fetches the data
  */
  // private _fetchData() {
  //   // Event category
  //   this.category = category;
  //   // Calender Event Data
  //   this.calendarEvents = this.data;
  //   // form submit
  //   this.submitted = false;
  // }

}
