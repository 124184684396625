import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';

import { WidgetModule } from './widget/widget.module';
import { RouterModule } from '@angular/router';
import { ViewFileComponent } from './components/view-file/view-file.component';
import {IconsModule} from "../pages/icons/icons.module";
import {EmployeesRequestsRoutingModule} from "../view/employees-requests/employees-requests-routing.module";
import {UiSwitchModule} from "ngx-ui-switch";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import {NgSelectModule} from "@ng-select/ng-select";
import {LightboxModule} from "ngx-lightbox";
import { SharedService } from './services/shared.service';
import { CheckfilePipe } from './pipes/checkfile.pipe';
import { CheckPermissionsDirective } from 'app/core/directives/check-permissions.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PdfInvoiceComponent } from './components/pdf/pdf-invoice/pdf-invoice.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from '@fullcalendar/list';
FullCalendarModule.registerPlugins([
  listPlugin, // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
  bootstrapPlugin
]);
@NgModule({
  declarations: [
    ViewFileComponent,
    CheckfilePipe,
    CheckPermissionsDirective,
    SafeHtmlPipe,
    PdfInvoiceComponent,
    CalendarComponent,
  ],
  imports: [
    CommonModule,
    UIModule,
    WidgetModule,
    CommonModule,
    IconsModule,
         UiSwitchModule.forRoot({
      size: 'small',
      color: '#393939',
      switchColor: '#FDB600',
      defaultBgColor: '#ccc',
      defaultBoColor : '#ccc',
      switchOffColor:'#111'
    }), 
    ReactiveFormsModule,
    FormsModule,
    UIModule,
    TranslateModule,
    NgxIntlTelInputModule,
    NgbPaginationModule,
    NgSelectModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbNavModule,
    NgbPaginationModule,
    NgSelectModule,
    LightboxModule,
    NgbTooltipModule,
    FullCalendarModule,
    NgbModalModule,

  ],
  exports: [
    CommonModule,
    UIModule,
    WidgetModule,
    CommonModule,
    IconsModule,
    // UiSwitchModule.forRoot({
    //   size: 'small',
    //   color: '#393939',
    //   switchColor: '#FDB600',
    //   defaultBgColor: '#ccc',
    //   defaultBoColor : '#ccc',
    //   switchOffColor:'#111'
    // }), 
    ReactiveFormsModule,
    FormsModule,
    UIModule,
    TranslateModule,
    NgxIntlTelInputModule,
    NgbPaginationModule,
    NgSelectModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbNavModule,
    NgbPaginationModule,
    NgSelectModule,
    LightboxModule,
    NgbTooltipModule,
    CheckfilePipe,
    SafeHtmlPipe,
    CheckPermissionsDirective,
    ViewFileComponent,
    PdfInvoiceComponent,
    CalendarComponent
  ],
  providers:[
    SharedService
  ]
})

export class SharedModule { }
