
<div class="container-fluid">
  <!-- <app-page-title title="Calendar" [breadcrumbItems]="breadCrumbItems"></app-page-title> -->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="app-calendar">
            <!-- calendar -->
            <full-calendar [options]="calendarOptions"></full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
