import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf-invoice',
  templateUrl: './pdf-invoice.component.html',
  styleUrls: ['./pdf-invoice.component.scss']
})
export class PdfInvoiceComponent implements OnInit {
  @Input() detailsIncome:any;
  constructor() { }

  ngOnInit(): void {
  }

}
