import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import {AuthenticationService} from '../services/auth.service'
@Directive({
  selector: '[checkPermissions]'
})
export class CheckPermissionsDirective implements OnChanges {
  @Input() set checkPermissions(val: any) {
    this.argument = val;
  }
  DecodedPermissions:any[] = [];
  private argument: any;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _AuthenticationService : AuthenticationService
  ) { 
    this.DecodedPermissions = this._AuthenticationService.DecodedToken.Permission;
  }

  public checkIsShowingNames(value: any[]): boolean {
    return value.some(item => this.DecodedPermissions.includes(item));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.checkPermissions) {
      if (this.checkIsShowingNames(this.argument)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
  }
}
