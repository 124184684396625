<div class="card-image text-center p-0 px-2 m-2" *ngIf="!view && path">
    <ng-container *ngIf="(file  | checkfile: file : path : true ) == 'image' ">
        <img  onerror="this.src = 'assets/images/brands/defult-image.png'"  class="p-0 w-100 image-file img-thumbnail" title="images" alt="images" [src]="path | safeHtml" />
    </ng-container>
    <ng-container *ngIf="(file  | checkfile: file : path : true ) == 'video' ">
        <video width="160" height="100" controls>
            <source [src]="path | safeHtml" type="video/mp4">
            <source [src]="path | safeHtml" type="video/ogg">
          </video>
    </ng-container>
    <ng-container *ngIf="(file  | checkfile: file : path : true ) == 'audio' ">
        <audio style="width: 100px;" controls>
            <source [src]="path | safeHtml" type="audio/ogg">
            <source [src]="path | safeHtml" type="audio/mpeg">
          </audio>
    </ng-container>
    <ng-container *ngIf="!['video' , 'audio' , 'image'].includes((file  | checkfile: file : path : true ))">
            <img  onerror="this.src = 'assets/images/brands/defult-image.png'" class="p-0 w-100 image-file img-thumbnail" title="images" alt="images"
                [src]="file  | checkfile: file : path" />
    </ng-container>
</div>


<!--when view file-->
<div class="card-image text-center p-0 px-2 m-2" *ngIf="view && path">
    <ng-container
        *ngIf="(file  | checkfile: file : path : true ) == 'image'">
        <img  onerror="this.src = 'assets/images/brands/defult-image.png'" (click)="_SharedService.viewImageWithOutId(path)" class="p-0 w-100 image-file img-thumbnail" title="images" alt="images" [src]="url+path | safeHtml" />
    </ng-container>
    <ng-container *ngIf="(file  | checkfile: file : path : true ) == 'video' ">
        <video width="160" height="100" controls>
            <source [src]="url+path | safeHtml" type="video/mp4">
            <source [src]="url+path | safeHtml" type="video/ogg">
          </video>
        <!-- <img  onerror="this.src = 'assets/images/brands/defult-image.png'"  class="p-0 w-100 image-file img-thumbnail" title="images" alt="images"  /> -->
    </ng-container>
    <ng-container *ngIf="(file  | checkfile: file : path : true ) == 'audio' ">
        <audio style="width: 100px;" controls>
            <source [src]="path | safeHtml" type="audio/ogg">
            <source [src]="path | safeHtml" type="audio/mpeg">
          </audio>
    </ng-container>
    <ng-container *ngIf="!['video' , 'audio' , 'image'].includes((file  | checkfile: file : path : true ))">
        <a (click)="_FilesService.openFile(path)">
            <img  onerror="this.src = 'assets/images/brands/defult-image.png'" class="p-0 w-100 image-file img-thumbnail" style="cursor: pointer;" title="images" alt="images" 
            [src]="file  | checkfile: file : path" />
        </a>
    </ng-container>
</div>