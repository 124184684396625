<div class="account-pages py-5 pt-sm-5 d-flex align-items-center" style="min-height: 100vh;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">{{'GENERAL.WELCOME_bACK' | translate}}</h5>
                  <p>{{'GENERAL.MESSAGE_LOGIN' | translate}}</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <p class="text-primary p-0 m-0 bold">ERP</p>
                  </span>
                </div>
              </a>
            </div>
            <ngb-alert type="danger" *ngIf="messageError && !submitted" [dismissible]="false">{{ messageError
              }}</ngb-alert>

            <div class="p-2">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                <div class="mb-3">
                  <label for="email">{{'GENERAL.EMAIL' | translate}}</label>
                  <input type="email" class="form-control" id="email" formControlName="userNameOrEmail" [ngClass]="{ 'is-valid': f.userNameOrEmail.dirty && !f.userNameOrEmail.errors ,
                            'is-invalid': f.userNameOrEmail.dirty && f.userNameOrEmail.errors}"
                    placeholder="Enter userNameOrEmail">
                  <div *ngIf="f.userNameOrEmail.dirty && f.userNameOrEmail.errors" class="invalid-feedback">
                    <div *ngIf="f.userNameOrEmail.errors.required">userNameOrEmail is required</div>
                    <div *ngIf="f.userNameOrEmail.errors.userNameOrEmail">Email must be a valid Email address
                    </div>
                  </div>
                </div>

                <div class="mb-3">

                  <label for="userpassword">{{'GENERAL.PASSWORD' | translate}}</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input [type]="hide ? 'text':'password'" class="form-control" formControlName="password" [ngClass]="{ 'is-valid': f.password.dirty && !f.password.errors ,
                            'is-invalid': f.password.dirty && f.password.errors}" placeholder="Enter password"
                      aria-label="Password" aria-describedby="password-addon">
                    <button class="btn btn-light " (click)="hide =! hide" type="button" id="password-addon"><i
                        class="mdi mdi-eye-outline"></i></button>
                    <div *ngIf="f.password.dirty && f.password.errors" class="invalid-feedback">
                      <span *ngIf="f.password.errors.required">Password is required</span>
                    </div>
                  </div>
                </div>

                <div class="mt-4 d-grid">
                  <button class="btn btn-primary btn-block" type="submit" [disabled]="loginForm.invalid">
                    <i class="bx bx-loader bx-spin font-size-16 align-middle me-2" *ngIf="submitted"></i>
                    {{'GENERAL.LOGIN' | translate}}
                  </button>
                </div>

              </form>
            </div>

          </div>
        </div>
        <div class="mt-5 text-center">
          <p>© {{year}} AFAQ. Crafted with <i class="mdi mdi-heart text-danger"></i> by AFAQ Developer</p>
        </div>

      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->
<!-- end container-fluid -->