<div class="content">
    <h1>الفاتورة</h1>

    <div style="width: 300px;" class="table-bordered mb-5">
        <table class="table mb-0 table-nowrap align-middle table-borderless">
            <tbody>
                <tr>
                    <th class="text-start">
                        <span > التاريخ :</span>
                        <span class="ms-2">{{detailsIncome.date}}</span>
                    </th>
                </tr>
                <tr>
                    <th class="text-start">
                        <span > رقم الفاتورة :</span>
                        <span class="ms-2">{{detailsIncome.codeNumber}}</span>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>


    <div class="row justify-content-between">
        <div class="col-6 table-bordered mb-5">
            <table class="table mb-0 table-nowrap align-middle table-borderless">
                <tbody>
                    <tr>
                        <th class="text-start">
                            <span > اسم الشركة :</span>
                            <span class="ms-2">{{detailsIncome.comapnyName}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-start">
                            <span >  عنوان البريد الالكترونى للعميل  : </span>
                            <span class="ms-2">{{detailsIncome?.email}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-start">
                            <span>العنوان : </span>
                            <span class="ms-2"></span>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-6 table-bordered mb-5">
            <table class="table mb-0 table-nowrap align-middle table-borderless">
                <tbody>
                    <tr>
                        <th class="text-start">
                            <span > اسم العميل :</span>
                            <span class="ms-2">{{detailsIncome.commercialName}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-start">
                            <span >  عنوان البريد الالكترونى للعميل  : </span>
                            <span class="ms-2">{{detailsIncome?.email}}</span>
                        </th>
                    </tr>
                    <tr>
                        <th class="text-start">
                            <span>العنوان : </span>
                            <span class="ms-2">{{detailsIncome.address}}</span>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <div class="table-responsive table-bordered">
        <table class="table mb-0 table-nowrap align-middle table-borderless">
            <thead>
                <tr>
                    <th scope="col">التاريخ</th>
                    <th scope="col">البيان</th>
                    <th scope="col">العدد</th>
                    <th scope="col">السعر</th>
                    <!-- <th scope="col">الاجمالى</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of detailsIncome.listOfItems">
                    <td>{{detailsIncome.date}}</td>
                    <td>{{item.itemCategoryName}}</td>
                    <td>{{1}}</td>
                    <td>{{item.amount - ((item.discount / 100) * item.amount) + ((item.tax / 100) * item.amount) }}</td>
                    <!-- <td>xxxxxxxxxxx</td> -->
                </tr>
                <tr>
                    <th>
                            الإجمالي
                    </th>
                    <th></th>
                    <th></th>
                    <th>
                        {{detailsIncome.finalAmount}}
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</div>