import { Injectable, Injector } from '@angular/core';
import { environment as env } from '@env/environment'
import { BehaviorSubject, Subject } from 'rxjs';
import { FilesService } from './files.service';
import { Lightbox } from 'ngx-lightbox';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isAddCompany:BehaviorSubject<boolean> = new BehaviorSubject(false);
  isRouteNotification$ = new Subject<number>();;
  companyID:BehaviorSubject<number> = new BehaviorSubject(+localStorage.getItem('companyId') | 0);
  RouteNotificatin$ = this.isRouteNotification$.asObservable();
  isReloadeCompany = this.isAddCompany.asObservable();
  companyID$ = this.companyID.asObservable();
  url: string = env.url;
  constructor(
    private _FilesService: FilesService,
    private lightbox:Lightbox
    ) { 
    }
  
  getRoute(companyId:number){
    this.companyID.next(companyId);
    localStorage.setItem('companyId',`${companyId}`)
  }

  routeNotification(id:number){
    this.isRouteNotification$.next(id);
  }

  formateFormData(body){
    const formDate = new FormData();
    for (const key in body) {
      if (Array.isArray(body[key]) && body[key].length > 0) {
        if (Object?.keys(body[key][0])?.length > 1) {
          body[key].forEach((ele: any, index: number) => {
            for (const subkey in ele){
              ele[subkey] && subkey != 'path' ? formDate.append(`${key}[${index}].${subkey}`, ele[subkey]) : null;
              ele[subkey] === 0 && subkey != 'path' ? formDate.append(`${key}[${index}].${subkey}`, ele[subkey]) : null;
            }

          })
        }
        else {
          body[key].forEach((ele: any) => {
            formDate.append(key, ele)
          })
        }
      } else {
        typeof body[key] === 'number' ? formDate.append(key, body[key]) : null;
        typeof body[key] === 'boolean' ? formDate.append(key, body[key]) : null;
        typeof body[key] === 'string' ? formDate.append(key, body[key]) : null;
        body[key]?.lastModified ? formDate.append(key, body[key]) : null;
      }
    }
    return formDate
  }

  checkQuery(query:any = {}) {
    const value:any = {};
    for (const key in query) {
      if (query[key]) {
        value[key] = query[key]; 
      }
    }
    return value
  }

  viewImage(idFile: number, files: any[]): void {
    let images = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (this._FilesService.typeImages.includes(this._FilesService.checkFileTtpe(null, files[i]))) {
          const src = this.url + files[i];
          const caption = files[i]?.description;
          const thumb = this.url + files[i];
          const id = idFile;
          const album = {
            src,
            caption,
            thumb,
            id
          };
          images.push(album);
        }
      }
    }
    let index = images.findIndex((x: any) => x.id == idFile)
    this.lightbox.open(images, index, {
      showZoom: true
    });
  }
  
  viewImageWithOutId(file: any): void {
    let images = [];
    if (file) {
      // for (let i = 0; i < files.length; i++) {
        if (this._FilesService.typeImages.includes(this._FilesService.checkFileTtpe(null, file))) {
          const src = this.url + file;
          const caption = file?.description;
          const thumb = this.url + file;
          // const id = idFile;
          const album = {
            src,
            caption,
            thumb,
            // id
          };
          
          images.push(album);
          console.log(images);
        // }
      }
    }
    this.lightbox.open(images, 0, {
      showZoom: true
    });
  }
}
