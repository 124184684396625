<div id="layout-wrapper">
  <app-sidebar [isCondensed]="isCondensed" (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-sidebar>
  <div class="main-content">
    <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
    </app-topbar>
    <div class="page-content pe-0">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
<app-rightsidebar></app-rightsidebar>