// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  // domain:'http://192.168.0.139:14797/api/',
  // url:'http://192.168.0.139:14797/',

  // domainAfaq : 'https://AFAQAPI.wabcgroup.com/api/',
  domain: 'https://ERP.wabcgroup.com/api/',
  url: 'https://ERP.wabcgroup.com/',

  firebaseConfig:{
    apiKey: "AIzaSyBaETgusRETI4CWsizZp6SDTcbDmzocAPw",
    authDomain: "erpproject-49e2a.firebaseapp.com",
    projectId: "erpproject-49e2a",
    storageBucket: "erpproject-49e2a.appspot.com",
    messagingSenderId: "817145707187",
    appId: "1:817145707187:web:bbec437c2adc3e8d5b8ec6",
    measurementId: "G-8ZZN4XFDJN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
