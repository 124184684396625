<!-- ========== Left Sidebar Start ========== -->
<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <ng-container *ngIf="USERERP">
        <!-- {{menuItems}} -->
        <li *ngIf="companyDetails" class="text-center mb-4">
          <a href="javascript:void(0);" class="no-hover border-0 d-flex justify-content-between align-items-center">
            <div  (click)="toggleMobileMenu($event)" *ngIf="!companyDetails?.logo" class="avatar-xs rounded-circle header-profile-user me-1">
              <div class=" rounded-circle">
                {{companyDetails?.name.charAt(0)}}
              </div>
            </div>
            <div (click)="toggleMobileMenu($event)"  *ngIf="companyDetails?.logo" class="avatar-xs rounded-circle header-profile-user me-1">
              <div class=" rounded-circle overflow-hidden">
                <img class="w-100" [src]="url+companyDetails?.logo"  onerror="this.src = '../../../../../assets/images/brands/defult-image.png'" alt="company image">
              </div>
            </div>
            <span>{{companyDetails?.name}}</span>
            <span (click)="toggleMobileMenu($event)">
              <img src="../../../assets/images/solar_double-alt-arrow-left-line-duotone.svg"  onerror="this.src = '../../../../../assets/images/brands/defult-image.png'" alt="arrow left line">
            </span>
          </a>
        </li>
        <li class="text-center mb-4">
          <a href="javascript:void(0);" class="no-hover border-0 d-flex flex-column align-items-center">
            <div  *ngIf="!companyDetails?.logo" class="rounded-circle">
              <div style="height: 54px;width: 54px;" class=" rounded-circle">
                {{companyDetails?.name.charAt(0)}}
              </div>
            </div>
            <div  *ngIf="companyDetails?.logo" class="rounded-circle">
              <div class=" rounded-circle overflow-hidden">
                <img class="w-100" width="54" height="54" [src]="url+companyDetails?.logo"  onerror="this.src = '../../../../../assets/images/brands/defult-image.png'" alt="company image">
              </div>
            </div>
            <span style="font-size: 16px;" class="my-2 fw-bold text-black">{{USERERP?.userName}}</span>
            <span class="fw-bold text-muted">Department Name</span>
          </a>
        </li>
        <ng-container *ngFor="let item of menuItems">
          <ng-container *checkPermissions="item.permission">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
              </a>
              <a [routerLink]="item.link" routerLinkActive="active" *ngIf="!hasItems(item)" class="side-nav-link-ref">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text}}</span>
              </a>
              <ul *ngIf="hasItems(item)" class="sub-menu xxxx">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *checkPermissions="subitem.permission">
                    <a [routerLink]="subitem.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
                      *ngIf="!hasItems(subitem)" class="side-nav-link-ref" [attr.data-parent]="subitem.parentId">
                      {{ subitem.label | translate}}
                      <span class="badge rounded-pill float-end bg-{{subitem.badge.variant}}"
                        *ngIf="subitem.badge">{{subitem.badge.text}} </span>
                    </a>
                    <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                      [attr.data-parent]="subitem.parentId" [class.active]="checkRouting(subitem.link)">
                      {{ subitem.label | translate}}
                      <span class="badge rounded-pill float-end bg-{{subitem.badge.variant}}"
                        *ngIf="subitem.badge">{{subitem.badge.text}} </span>
                    </a>
                    <ul *ngIf="hasItems(subitem)" class="sub-menu ">
                      <li *ngFor="let subSubitem of subitem.subItems">
                        <ng-container *checkPermissions="subSubitem.permission">
                          <a *ngIf="!hasItems(subSubitem)" [attr.data-parent]="subSubitem.parentId"
                            [routerLink]="subSubitem.link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact:true}" [class.active]="checkRouting(subSubitem.link)"
                            class="side-nav-link-ref">
                            {{ subSubitem.label | translate }}
                            <span class="badge rounded-pill float-end bg-{{subSubitem.badge.variant}}"
                              *ngIf="subSubitem.badge">{{subSubitem.badge.text}} </span>
                          </a>
                          <a *ngIf="hasItems(subSubitem)" class="side-nav-link-a-ref has-arrow"
                            href="javascript:void(0);" [attr.data-parent]="subSubitem.parentId"
                            [class.active]="checkRouting(subSubitem.link)">
                            {{ subSubitem.label | translate}}
                            <span class="badge rounded-pill float-end bg-{{subSubitem.badge.variant}}"
                              *ngIf="subSubitem.badge">{{subSubitem.badge.text}} </span>
                          </a>
                          <ul *ngIf="hasItems(subSubitem)" class="sub-menu">
                            <li *ngFor="let subSubSubitem of subSubitem.subItems">
                              <ng-container *checkPermissions="subSubSubitem.permission">
                                <a [attr.data-parent]="subSubSubitem.parentId" [routerLink]="subSubSubitem.link"
                                  routerLinkActive="active" [class.active]="checkRouting(subSubSubitem.link)"
                                  class="side-nav-link-ref">
                                  {{ subSubSubitem.label | translate }}
                                </a>
                              </ng-container>
                            </li>
                          </ul>
                        </ng-container>
                      </li>
                    </ul>
                  </ng-container>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>

<!-- Left Sidebar End -->