import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const lang = JSON.parse(localStorage.getItem('lang_ERP'))?.lang || 'ar'
        const currentUser: any = this.authenticationService.getUser()
        if (currentUser) {
            request = request.clone({
                setHeaders: {
                    'lang': lang,
                    'Accept-Language': `${lang}-${lang == 'ar' ? 'EG':'US'}`,
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request);
    }
}
