import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import * as fileS from 'file-saver';
import { Workbook } from 'exceljs';
import { GenerateExcel } from 'app/shared/services/generate-excel.service';

export interface DataExcle {
  bodyList: any[]
  headerList: any[]
  title: string
  fileName: string
  img: string
}
@Injectable({
  providedIn: 'root'
})
export class FilesService {
  url: string = env.url;
  typeImages: any = ['image'];
  typeFiles: any = [
    'pdf',
    'word',
    'excel',
    'audio',
    'video',
    'archive',
    'svg',
    'ait',
    'eps',
    'psd',
    'psb',
    'tif',
    'ai',
    'pptx',
    'unknown'];
  iconFiles: any = [
    { type: '', icon: '' }
  ]
  constructor() { }

  // downloadFile(fileUrl) {
  //   const type = fileUrl.file.split('.').pop();
  //   let headers = new Headers();
  //   headers.append('Access-Control-Allow-Origin', '*');
  //   headers.append('Access-Control-Allow-Credentials', 'true');
  //   headers.append('Access-Control-Allow-Methods','GET, POST, OPTIONS');

  //   fetch(this.url + fileUrl.file, {
  //     mode: 'no-cors',
  //     credentials: 'include',
  //     method: 'GET',
  //     headers: headers
  //   })
  //     .then(response => response.blob())
  //     .then(blob => {
  //       console.log(blob);
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob);
  //       link.download = `${fileUrl.description}.${type}`;
  //       link.click();
  //     })
  //     .catch(console.error);
  // }

  // downloadFile(fileUrl) {
  //   fetch(this.url + fileUrl.file, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
  //     .then(res => res.blob())
  //     .then(res => {
  //       const aElement = document.createElement('a');
  //       aElement.setAttribute('download', fileUrl.description);
  //       const href = URL.createObjectURL(res);
  //       aElement.href = href;
  //       // aElement.setAttribute('target', '_blank');
  //       aElement.click();
  //       URL.revokeObjectURL(href);
  //     });
  // }
  checkFileTtpe(File: any, path: string) {
    let nameType: string = '';
    const type: string = !File ? path.split('.').pop() : File.name.split('.').pop();

    switch (type.toLowerCase()) {
      case 'pdf':
      case 'pdd':
        nameType = 'pdf';
        break;
      case 'docx':
      case 'doc':
        nameType = 'word';
        break;
      case 'pptx':
        nameType = 'pptx';
        break;
      case 'xls':
      case 'xlsx':
        nameType = 'excel';
        break;
      case 'mp3':
      case 'ogg':
      case 'wav':
        nameType = 'audio';
        break;
      case 'mp4':
      case 'mov':
        nameType = 'video';
        break;
      case 'zip':
      case '7z':
      case 'rar':
        nameType = 'archive';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        nameType = 'image';
        break;
      case 'svg':
      case 'svgz':
        nameType = 'svg';
        break;
      case 'ait':
        nameType = 'ait';
        break;
      case 'eps':
        nameType = 'eps';
        break;
      case 'psd':
      case 'pdd':
        nameType = 'psd';
        break;
      case 'psb':
        nameType = 'psb';
        break;
      case 'tif':
        nameType = 'tif';
        break;
      case 'ai':
        nameType = 'ai';
        break;
      default:
        nameType = 'unknown';
    }
    return nameType;
    // return type.toLowerCase()
  }

  openFile(fileUrl: any) {
    window.open(this.url + fileUrl)
  }
  async exportExcel(value: DataExcle) {
    const excel = new GenerateExcel();
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(' ');
    // await excel
    //   .getBase64ImageFromUrl(`${value.img}`)
    //   .then((dataUrl: any) => {
    //     let logo = workbook.addImage({ base64: dataUrl, extension: 'png' });
    //     worksheet.addImage(logo, 'F2:F8');
    //   });
    // worksheet.addRow(['']);
    // worksheet.addRow(['']);
    // const titleRow1 = worksheet.addRow([
    //   // '',
    // ]);
    // excel.headStyle2(titleRow1);
    // excel.mergeCell('A3:C3', worksheet);
    worksheet.addRow(['']);
    worksheet.addRow(['']);

    const titleRow2 = worksheet.addRow([`${value.fileName}`]);
    excel.headStyle2(titleRow2);
    excel.mergeCell('A3:F3', worksheet);

    // const titleRow3 = worksheet.addRow(['']);
    // excel.headStyle2(titleRow3);
    // excel.mergeCell('A8:C8', worksheet);
    worksheet.addRow(['']);
    const headerRow = worksheet.addRow([
      '',
      ...value.headerList
    ]);
    let valueList: any = {};
    let eleKey: any[]
    value.bodyList.forEach((ele: any, index: number) => {
      eleKey = [];
      for (const key in ele) {
        eleKey.push(ele[key]);
      }
      valueList[index] = eleKey
    })
    excel.tableHeadStyle1(headerRow, 1);
    value.bodyList.forEach((ele: any, i: number) => {
      let row = worksheet.addRow([
        '',
        ...valueList[i]
      ]);
      row.alignment = { vertical: 'middle', horizontal: 'center' };
    })

    worksheet.columns.forEach((column, index) => {
      column.width = 33;
    });
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fileS.saveAs(blob, `${value.title}` + '.xlsx');
    });
  
  }
}
