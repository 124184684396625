import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkfile'
})
export class CheckfilePipe implements PipeTransform {

  transform(value: any, File: any, path: any, justNameType: boolean = false): any {
    let nameType: string = '';
    const type: string = !File ? path.split('.').pop() : File.name.split('.').pop();
    
    switch (type.toLowerCase()) {
      case 'pdf':
      case 'pdd':
        nameType = 'pdf';
        break;
      case 'docx':
      case 'doc':
        nameType = 'word';
        break;
      case 'pptx':
        nameType = 'pptx';
        break;
      case 'xls':
      case 'xlsx':
        nameType = 'excel';
        break;
      case 'mp3':
      case 'ogg':
      case 'wav':
        nameType = 'audio';
        break;
      case 'mp4':
      case 'mov':
        nameType = 'video';
        break;
      case 'zip':
      case '7z':
      case 'rar':
        nameType = 'archive';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        nameType = 'image';
        break;
      case 'svg':
      case 'svgz':
        nameType = 'svg';
        break;
      case 'ait':
        nameType = 'ait';
        break;
      case 'eps':
        nameType = 'eps';
        break;
      case 'psd':
      case 'pdd':
        nameType = 'psd';
        break;
      case 'psb':
        nameType = 'psb';
        break;
      case 'tif':
        nameType = 'tif';
        break;
      case 'ai':
        nameType = 'ai';
        break;
      default:
        nameType = 'unknown';
    }
    if (justNameType) {
      return nameType
    }
    return `../../../../../assets/images/brands/${nameType}.png`;
  }

}
