import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'ar'];

  constructor(public translate: TranslateService, private cookieService: CookieService , private _localeService: BsLocaleService) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang_ERP')) {
      browserLang = this.cookieService.get('lang_ERP');
    }
    else {
      this.setLanguage('en');
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|it|ru/) ? browserLang : 'en');
  }

  public setLanguage(lang:string) {
    this.translate.use(lang);
    // this._localeService.use(lang.toLowerCase());
    this.cookieService.set('lang_ERP', lang);
    
  }

}
