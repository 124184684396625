import { Row, Workbook, Worksheet } from 'exceljs';
//@ts-ignore
import * as fileS from 'file-saver';

export class GenerateExcel {
  constructor() { }

  /**
   * This Function To Merge Cells
   * @param Input Column Name like 'A1:A10'
   */
  mergeCell(columnName: string, worksheet: Worksheet): void {
    worksheet.mergeCells(columnName);
  }

  headStyle1(row: Row): Row {
    row.font = { name: 'Century Gothic', family: 4, size: 12, bold: true };
    row.alignment = { horizontal: 'center', vertical: 'middle' };
    return row;
  }

  headStyle2(row: Row): Row {
    row.font = { name: 'Century Gothic', family: 4, size: 24, bold: true };
    row.alignment = { horizontal: 'center', vertical: 'middle' };
    return row;
  }

  headStyle3(row: Row): Row {
    row.font = { name: 'Century Gothic', bold: true, size: 12, family: 4 };
    row.alignment = { horizontal: 'right', vertical: 'middle' };
    return row;
  }

  headStyle4(row: Row): Row {
    row.font = { name: 'Century Gothic', bold: true, size: 12, family: 4 };
    row.alignment = { horizontal: 'right', vertical: 'middle' };
    return row;
  }

  tableHeadStyle1(row: Row, skipRow: number = 0): Row {
    row.eachCell((cell, number) => {
      if (number > skipRow) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4baf50' },
          bgColor: { argb: '4baf50' },
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.font = { name: 'Century Gothic', size: 12, bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      }
    });
    return row;
  }

  async getBase64ImageFromUrl(imageUrl: string) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  convertJsonToValueStringArray(Data: any[]): any[][] {
    return [...Data.map((x) => Object.values(x))];
  }

  convertJsonToKeyStringArray(Data: any[]): string[] {
    return [...Object.keys(Data[0]).map((x) => x)];
  }




}
