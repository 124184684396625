import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilesService } from 'app/shared/services/files.service';
import { SharedService } from 'app/shared/services/shared.service';
import { environment as env } from '@env/environment';
@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss']
})
export class ViewFileComponent implements OnInit {
  @Input() file:any;
  @Input() path:any;
  @Input() view:boolean = false;
  url: string = env.url;

  @Output() onRemoveImage:EventEmitter<any> = new EventEmitter() ;
  constructor(
    public _SharedService: SharedService,
    public _FilesService: FilesService
  ) { }

  ngOnInit(): void {
  }

}
